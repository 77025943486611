import React from 'react';
import '../App.css';

const Privacy = () => {

	return (
		<div className='privacy'>
            <h1>Local Perks LLC - Privacy Policy</h1>
            <p>1. Introduction</p>
            <p>Welcome to PerksPass, a mobile application provided by Local Perks LLC ("Company," "we," "our," or "us"). This Privacy Policy outlines our practices regarding the collection, use, and disclosure of information when you use the "PerksPass" mobile app ("App"). By accessing or using the App, you consent to the practices described in this Privacy Policy.</p>
            <p>2. Information We Collect</p>
            <p>2.1 Personal Information: We may collect personal information you provide directly to us, such as your name, email address, and location. This information is used to create and manage your account, provide customer support, and communicate with you about the App and related promotions.</p>
            <p>2.2 Usage Information: We collect information about your use of the App, including your interactions with the promo codes, discounts, and merchants. This information helps us understand how you use the App and improve its functionality.</p>
            <p>2.3 Device Information: We may collect information about your mobile device, including device type, operating system, and device identifiers. This information is used to optimize the App for your device and troubleshoot technical issues.</p>
            <p>2.4 Location Information: With your permission, we may collect precise location information from your mobile device. This information is used to provide location-based services and to show you relevant discounts from nearby merchants.</p>
            <p>3. How We Use Your Information</p>
            <p>3.1 Provide and Improve the App: We use the information we collect to operate, maintain, and improve the App, including enhancing user experience and developing new features.</p>
            <p>3.2 Communication: We may use your contact information to send you emails or push notifications about promotions, updates, and relevant information related to the App. You can opt out of these communications at any time.</p>
            <p>3.3 Analytics: We may use analytics tools to understand how users interact with the App, which helps us improve its performance and functionality.</p>
            <p>3.4 Legal Purposes: We may use your information to comply with legal obligations, resolve disputes, and enforce our agreements.</p>
            <p>4. Sharing of Information</p>
            <p>4.1 With Merchants: We may share aggregated and anonymized usage data with our merchant partners to help them understand the effectiveness of their discounts.</p>
            <p>4.2 Service Providers: We may share your information with third-party service providers who assist us in providing, maintaining, and improving the App. These providers are bound by confidentiality agreements.</p>
            <p>4.3 Legal Compliance: We may disclose your information if required by law, court order, or governmental authority.</p>
            <p>4.4 Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.</p>
            <p>5. Your Choices</p>
            <p>You can access, update, or delete your account information within the App's settings. You can also manage your preferences for receiving promotional communications.</p>
            <p>6. Security</p>
            <p>We implement reasonable security measures to protect your information from unauthorized access and disclosure. However, no data transmission over the internet can be guaranteed as completely secure.</p>
            <p>7. Children's Privacy</p>
            <p>The App is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children.</p>
            <p>8. Changes to this Privacy Policy</p>
            <p>We may update this Privacy Policy to reflect changes to our information practices. We will notify you of any material changes through the App or other means. Your continued use of the App after such changes indicates your acceptance of the revised Privacy Policy.</p>
            <p>9. Contact Us</p>
            <p>If you have questions or concerns about this Privacy Policy, please contact Local Perks LLC.</p>
            <p>By using the "PerksPass" mobile app, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>
        </div>
	);
};

export default Privacy;
